import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation, useBi } from '@wix/yoshi-flow-editor';
import {
  CoverImage,
  PrivacyStatus,
} from '@wix/ambassador-social-groups-v2-group/types';
import { groupSettingsSave } from '@wix/bi-logger-groups/v2';

import { selectGroup, selectIsGroupUpdating } from 'store/groups/selectors';

import { BIUserEntry } from 'common/bi-logger/types';
import { useDidUpdate, useFileUploader } from 'common/hooks';
import { BlackAndWhiteTextField } from 'common/components/BlackAndWhiteTextField';
import { BlackAndWhiteImageLoader } from 'common/components/BlackAndWhiteImageLoader';
import { GroupPrivacySelect } from 'common/components/GroupPrivacySelect';
import { useController } from 'common/context/controller';

import { GroupSettingsContext } from '../../GroupSettingsContext';

interface IDetailsTabProps {
  groupId: string;
  onClose(): void;
}

export function DetailsTab(props: IDetailsTabProps) {
  const { groupId } = props;
  const { group$ } = useController();

  const bi = useBi();
  const { t } = useTranslation();

  const group = useSelector(selectGroup(groupId));
  const isUpdating = useSelector(selectIsGroupUpdating(groupId));
  const fileUploader = useFileUploader();

  const groupSettings = React.useContext(GroupSettingsContext);
  const $imageLoader = React.useRef<BlackAndWhiteImageLoader>();

  const [isUploading, setIsUploading] = React.useState(false);
  const [title, setTitle] = React.useState<string>();
  const [privacy, setPrivacy] = React.useState<PrivacyStatus>();
  const [imageUrl, setImageUrl] = React.useState<string>();

  React.useEffect(() => {
    groupSettings.set({
      submitButtonProps: {
        loading: isUpdating || isUploading,
        disabled: !title || isUpdating || isUploading,
        onClick: handleSave,
      },
    });
  }, [title, privacy, isUpdating, isUploading]);

  React.useEffect(() => {
    const { name, privacyStatus, coverImage } = group;

    setTitle(name as string);
    setPrivacy(privacyStatus);
    setImageUrl(
      (coverImage?.image?.mediaId || coverImage?.image?.fileUrl) as string,
    );
  }, [group.id]);

  useDidUpdate(() => {
    if (!isUpdating) {
      props.onClose();
    }
  }, [isUpdating]);

  return (
    <div>
      <BlackAndWhiteTextField
        maxLength={50}
        value={title}
        onChange={handleTitleChange}
        label={t('groups-web.group.settings.name.label')}
      />
      <GroupPrivacySelect
        onChange={setPrivacy}
        value={privacy as PrivacyStatus}
      />
      <BlackAndWhiteImageLoader
        imageUrl={imageUrl}
        ref={handleImageLoaderRef}
        uploadLabel={t('groups-web.group.settings.details.upload_image')}
        changeLabel={t('groups-web.group.settings.details.change_image')}
        deleteLabel={t('groups-web.group.settings.details.delete_image')}
      />
    </div>
  );

  function handleImageLoaderRef(ref: BlackAndWhiteImageLoader) {
    $imageLoader.current = ref;
  }

  async function handleSave() {
    group$.updateGroupInfo(groupId, {
      name: title,
      privacyStatus: privacy,
      coverImage: await getCoverImageChanges(),
    });

    bi.report(
      groupSettingsSave({
        userEntry: BIUserEntry.SITE,
        group_id: groupId,
        origin: 'save_button',
        tabName: 'details',
        type: privacy,
        withPhoto: $imageLoader.current?.isChanged(),
        display_cover: true,
      }),
    );
  }

  async function getCoverImageChanges(): Promise<CoverImage | undefined> {
    const logo = $imageLoader.current?.getFile();
    const isChanged = $imageLoader.current?.isChanged();

    if (!isChanged) {
      return undefined;
    }

    if (!logo) {
      return {};
    }

    setIsUploading(true);

    const {
      height,
      width,
      file_name: mediaId,
    } = await fileUploader.upload(logo);

    setIsUploading(false);

    return {
      position: { x: 0, y: 50 },
      mobilePosition: { x: 0, y: 50 },
      image: {
        mediaId,
        height,
        width,
      },
    };
  }

  function handleTitleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setTitle(event.target.value);
  }
}
