import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  createHttpClient,
  useEnvironment,
  useErrorMonitor,
} from '@wix/yoshi-flow-editor';

import { getUploadUrl, IUploadResponse } from 'api/media-manager';

import { selectUserToken } from 'store/application/selectors';

export function useFileUploader() {
  const { isSSR } = useEnvironment();
  const instance = useSelector(selectUserToken);
  const errorMonitor = useErrorMonitor();

  const httpClient = useMemo(
    () =>
      createHttpClient({
        isSSR,
        getAppToken() {
          return instance;
        },
      }),
    [instance, isSSR],
  );

  return {
    async upload(file: File) {
      const { data: url } = await httpClient.request(
        getUploadUrl(file.name, file.type, file.size),
      );

      const data = new FormData();
      data.append('file', file, file.name);

      try {
        const response = await httpClient.post<IUploadResponse[]>(url, data, {
          headers: { 'Content-Type': 'multipart/form-data;' },
        });

        return response.data[0];
      } catch (err) {
        errorMonitor.captureException(err as Error);
        console.error(err);
        return Promise.reject(err);
      }
    },
  };
}
