import React from 'react';

import { useSelector } from 'react-redux';
import { useSettings } from '@wix/tpa-settings/react';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import { selectGroupIdBySlug } from 'store/groups/selectors';
import { useCurrentStateAndParams } from 'common/router';
import { Container } from 'wui/Container';

import { CoverImageLayout } from '../../Settings/settingsConstants';
import settingsParams from '../../settingsParams';

import { GROUP_HEADER } from '../dataHooks';

import {
  MobileLayout,
  LargeImageLayout,
  ThumbnailImageLayout,
} from './layouts';

export const Header: React.FC = () => {
  const { params } = useCurrentStateAndParams();
  const environment = useEnvironment();
  const settings = useSettings();

  const groupId = useSelector(selectGroupIdBySlug(params.slug));
  const coverImageLayout = settings.get(settingsParams.coverImageLayout);
  const hasImage = coverImageLayout !== CoverImageLayout.blank;

  if (environment.isMobile) {
    return (
      <Container fluid data-hook={GROUP_HEADER}>
        <MobileLayout withImage={hasImage} groupId={groupId} />
      </Container>
    );
  }

  switch (coverImageLayout) {
    case CoverImageLayout.large:
      return (
        <Container fluid data-hook={GROUP_HEADER}>
          <LargeImageLayout groupId={groupId} />
        </Container>
      );

    case CoverImageLayout.thumbnail:
    case CoverImageLayout.blank:
    default:
      return (
        <Container data-hook={GROUP_HEADER}>
          <ThumbnailImageLayout withImage={hasImage} groupId={groupId} />
        </Container>
      );
  }
};

Header.displayName = 'Header';
