import React from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { CSSTransitionClassNames } from 'react-transition-group/CSSTransition';
import {
  ErrorMonitorBoundary,
  useEnvironment,
  useExperiments,
} from '@wix/yoshi-flow-editor';

import { useCurrentStateAndParams, useRouter } from 'common/router';
import { Container } from 'wui/Container';
import { ErrorState } from '../../ErrorState';

import { DiscussionPage } from '../../DiscussionPage/loadable';
import { MediaPage } from '../../MediaPage/loadable';
import { AboutPage } from '../../AboutPage/loadable';
import { EventsPage } from '../../EventsPage/loadable';
import { MembersPage } from '../../MembersPage/loadable';
import { FilesPages } from '../../FilesPage/loadable';
import { CustomTabPage } from '../../CustomTabPage/loadable';

import { classes, st } from './ApplicationPage.st.css';

const routes = [
  {
    state: 'group.discussion',
    component: DiscussionPage,
  },
  {
    state: 'group.media',
    component: MediaPage,
  },
  {
    state: 'group.files',
    component: FilesPages,
  },
  {
    state: 'group.about',
    component: AboutPage,
  },
  {
    state: 'group.events',
    component: EventsPage,
  },
  {
    state: 'group.members',
    component: MembersPage,
  },
  {
    state: 'group.custom-tab',
    component: CustomTabPage,
  },
];

export function ApplicationPage() {
  const { state } = useCurrentStateAndParams();
  const { isMobile } = useEnvironment();
  const { experiments } = useExperiments();
  const router = useRouter();

  const useImprovedLayout = experiments.enabled(
    'specs.groups.GroupLayoutImprovements',
  );

  const active = routes.find((item) => state?.name?.includes(item.state));
  const Page = active?.component || null;

  return (
    <ErrorMonitorBoundary fallback={<ErrorState onRetry={handleRetry} />}>
      <SwitchTransition>
        <CSSTransition
          key={state.name}
          timeout={300}
          classNames={classes as CSSTransitionClassNames}
        >
          <Container fluid={isMobile}>
            <section
              aria-labelledby={state.data?.application}
              className={st(classes.root, {
                mobile: isMobile,
                improvedLayout: useImprovedLayout,
              })}
            >
              {Page ? <Page /> : null}
            </section>
          </Container>
        </CSSTransition>
      </SwitchTransition>
    </ErrorMonitorBoundary>
  );

  function handleRetry() {
    router.reload();
  }
}
