import React from 'react';

export const PhotoCamera: React.FC = (props) => (
  <svg viewBox="0 0 21 17" {...props}>
    <defs>
      <path
        id="a"
        d="M15.414 3.96c.465 0 .892.245 1.13.638l.065.122.881 1.88h3.63c.684 0 1.246.52 1.314 1.185l.006.135v10.56a1.32 1.32 0 0 1-1.185 1.313l-.135.007H3.96a1.32 1.32 0 0 1-1.313-1.185l-.007-.135V7.92c0-.683.52-1.246 1.185-1.313L3.96 6.6h3.63l.881-1.88a1.32 1.32 0 0 1 1.057-.753l.139-.007h5.747zm-.258 1.32H9.924L8.58 7.92H3.96v10.56h17.16V7.92H16.5l-1.344-2.64zM12.54 9.24a3.3 3.3 0 1 1 0 6.6 3.3 3.3 0 0 1 0-6.6zm0 1.32a1.98 1.98 0 1 0 0 3.96 1.98 1.98 0 0 0 0-3.96z"
      />
    </defs>
    <g fillRule="evenodd" transform="translate(-2 -3)">
      <mask id="b">
        <use xlinkHref="#a" />
      </mask>
      <use fillRule="nonzero" xlinkHref="#a" />
      <g mask="url(#b)">
        <path d="M0 0h25.08v25.08H0z" />
      </g>
    </g>
  </svg>
);
