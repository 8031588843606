import React from 'react';

import {
  TextButton,
  ButtonPriority,
  WowImage,
  TextButtonPriority,
} from 'wix-ui-tpa';
import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';
import { ImageResizeOptions } from 'wix-ui-tpa/dist/src/components/Image/types';

import { Change } from '../Icons/Change';
import { Delete } from '../Icons/Delete';
import { PhotoCamera } from '../Icons/PhotoCamera';
import { BlackAndWhiteButton } from '../BlackAndWhiteButton';

import { st, classes } from './BlackAndWhiteImageLoader.st.css';

interface BlackAndWhiteImageLoaderProps extends TPAComponentProps {
  onChange?(image?: File): void;

  imageUrl?: string;
  changeLabel?: string;
  deleteLabel?: string;
  uploadLabel?: string;
}

export interface BlackAndWhiteImageLoaderState {
  imageUrl?: string;
  file?: File;
}
export class BlackAndWhiteImageLoader extends React.Component<
  BlackAndWhiteImageLoaderProps,
  BlackAndWhiteImageLoaderState
> {
  private readonly $input = React.createRef<HTMLInputElement>();

  readonly state: BlackAndWhiteImageLoaderState = {
    imageUrl: this.props.imageUrl,
  };

  componentDidUpdate(prevProps: BlackAndWhiteImageLoaderProps) {
    if (prevProps.imageUrl !== this.props.imageUrl) {
      this.setState({
        imageUrl: this.props.imageUrl,
        file: undefined,
      });
    }
  }

  handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const [file] = Array.from(event.target.files!);

    this.props.onChange?.(file);
    this.setState({
      file,
      imageUrl: URL.createObjectURL(file),
    });

    this.$input.current!.value = null as any;
  };

  handleImageChange = () => {
    if (this.state.imageUrl) {
      URL.revokeObjectURL(this.state.imageUrl);
    }

    return this.handleUploadButtonClick();
  };

  handleImageDelete = () => {
    if (this.state.imageUrl) {
      URL.revokeObjectURL(this.state.imageUrl);
    }
    this.props.onChange?.();
    this.setState({ imageUrl: undefined, file: undefined });
  };

  isChanged() {
    return this.props.imageUrl !== this.state.imageUrl;
  }

  getFile() {
    return this.state.file;
  }

  handleUploadButtonClick = () => this.$input.current!.click();

  render() {
    const { className } = this.props;
    const { imageUrl } = this.state;

    return (
      <div className={st(classes.root, {}, className)}>
        {imageUrl ? (
          <div data-hook="editor" className={classes.editor} tabIndex={0}>
            <WowImage src={imageUrl} resize={ImageResizeOptions.cover} />
            <div className={classes.actions}>
              <TextButton
                data-hook="change-image-button"
                onClick={this.handleImageChange}
                prefixIcon={<Change />}
                priority={TextButtonPriority.secondary}
                className={classes.button}
              >
                {this.props.changeLabel || 'Change Image'}
              </TextButton>
              <TextButton
                data-hook="delete-image-button"
                onClick={this.handleImageDelete}
                prefixIcon={<Delete />}
                priority={TextButtonPriority.secondary}
                className={classes.button}
              >
                {this.props.deleteLabel || 'Delete Image'}
              </TextButton>
            </div>
          </div>
        ) : (
          <div className={classes.uploadButtonWrapper}>
            <BlackAndWhiteButton
              fullWidth
              data-hook="upload-image-button"
              priority={ButtonPriority.secondary}
              onClick={this.handleUploadButtonClick}
              className={classes.uploadButton}
            >
              <PhotoCamera />
              {this.props.uploadLabel || 'Upload Image'}
            </BlackAndWhiteButton>
          </div>
        )}
        <input
          hidden
          data-hook="image-input"
          accept="image/*"
          type="file"
          multiple={false}
          ref={this.$input}
          onChange={this.handleFileInputChange}
        />
      </div>
    );
  }
}
