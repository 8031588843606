import React from 'react';
import type { WidgetProps } from '@wix/yoshi-flow-editor';
import { WixCommentsApiProvider } from '@wix/comments-ooi-client';
import { useSettings, useStyles } from '@wix/tpa-settings/react';

import { SocialGroupStore } from 'store/SocialGroupsStore';

import { ThemeProvider } from 'common/context/theme/ThemeContext';
import { ControllerProvider } from 'common/context/controller';
import { Router } from 'common/router';
import { Toasts } from 'common/components/Toasts';
import { useShadowStyles } from 'common/hooks/useShadowStyles';

import settingsParams from 'Group/settingsParams';
import stylesParams from 'Group/stylesParams';

import type { IControllerProps } from '../../../controllers/types';

import { Group } from './Group';
import { GROUP_WRAPPER } from './dataHooks';

export default function Widget(props: WidgetProps<IControllerProps>) {
  const settings = useSettings();
  const styles = useStyles();
  const shadowStyles = useShadowStyles();

  return (
    <SocialGroupStore state={props.store}>
      <ControllerProvider {...props}>
        <Toasts />
        <Router>
          <WixCommentsApiProvider {...props}>
            <ThemeProvider
              host={props.host}
              buttonType={settings.get(settingsParams.buttonType)}
              buttonBorderRadius={styles.get(stylesParams.buttonBorderRadius)}
            >
              <div data-hook={GROUP_WRAPPER} ref={shadowStyles.onRefChange}>
                <Group />
              </div>
            </ThemeProvider>
          </WixCommentsApiProvider>
        </Router>
      </ControllerProvider>
    </SocialGroupStore>
  );
}
