import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useBi, useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import { groupsAddMemberClicked } from '@wix/bi-logger-groups/v2';

import { Add } from '@wix/wix-ui-icons-common';
import { ButtonPriority } from 'wix-ui-tpa';

import { selectCanInviteMembers } from 'store/groups/selectors';

import { Button } from 'common/components/Button';

import { InviteMembersModal } from './InviteMembersModal';
import { INVITE_MEMBERS } from './dataHooks';

interface IInviteMembersProps {
  groupId: string;
}

export function InviteMembers(props: IInviteMembersProps) {
  const { groupId } = props;

  const bi = useBi();
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const canInvite = useSelector(selectCanInviteMembers(groupId));

  const [isOpen, setIsOpen] = useState(false);

  if (!canInvite) {
    return null;
  }

  return (
    <>
      <Button
        fullWidth
        priority={ButtonPriority.secondary}
        prefixIcon={isMobile ? undefined : <Add width="24px" height="24px" />}
        onClick={openModal}
        data-hook={INVITE_MEMBERS}
        area-label={t('groups-web.a11y.invite-members')}
      >
        {t('groups-web.group.actions.invite-members.title')}
      </Button>
      <InviteMembersModal
        isOpen={isOpen}
        groupId={groupId}
        onClose={closeModal}
      />
    </>
  );

  function openModal() {
    setIsOpen(true);

    bi.report(
      groupsAddMemberClicked({
        groupId,
        origin: 'group_member_icon_btn',
      }),
    );
  }

  function closeModal() {
    setIsOpen(false);
  }
}

InviteMembers.displayName = 'InviteMembers';
