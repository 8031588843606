import React from 'react';
import cls from 'classnames';
import { useEnvironment } from '@wix/yoshi-flow-editor';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

import { classes } from './Container.st.css';

interface IContainerProps
  extends TPAComponentProps,
    React.HTMLAttributes<HTMLDivElement> {
  fluid?: boolean;
}

export const Container: React.FC<IContainerProps> = (props) => {
  const { children, className, fluid, ...rest } = props;

  const { isMobile } = useEnvironment();

  return (
    <div
      className={cls(classes.root, className, {
        [classes.fluid]: fluid,
        [classes.mobile]: isMobile,
      })}
      {...rest}
    >
      {children}
    </div>
  );
};

Container.displayName = 'Container';
