import React from 'react';

export const Delete: React.FC = (props) => (
  <svg viewBox="0 0 14 18" width="14" height="18" {...props}>
    <g fillRule="evenodd">
      <path
        fillRule="nonzero"
        d="M4.277.667h5.459a.5.5 0 0 1 .492.41l.008.09v1.825H14v.979l-1.461-.001.03 10.717a2.771 2.771 0 0 1-.589 1.714 2.11 2.11 0 0 1-1.486.803l-.176.007H3.685a2.06 2.06 0 0 1-1.642-.815A2.812 2.812 0 0 1 1.48 14.9l-.008-.204V3.97H0v-.977l3.776-.001V1.167a.5.5 0 0 1 .411-.492l.09-.008h5.459zm7.285 3.314H2.45v10.716c0 .4.131.79.373 1.108.179.236.446.387.736.421l.126.007h6.633c.348 0 .677-.16.892-.434.212-.271.34-.597.372-.938l.008-.172-.028-10.708zM9.236 1.667h-4.46v1.325h4.46V1.667z"
      />
      <path fill="transparent" d="M-5-3h24v24H-5z" />
    </g>
  </svg>
);
