import React from 'react';

export const Clock: React.FC = (props) => (
  <svg viewBox="0 0 24 24" {...props}>
    <g id="time-icon">
      <path
        d="M12,21 C7.02943726,21 3,16.9705627 3,12 C3,7.02943726 7.02943726,3 12,3 C16.9705627,3 21,7.02943726 21,12 C21,16.9705627 16.9705627,21 12,21 Z M12,19.8 C16.3078211,19.8 19.8,16.3078211 19.8,12 C19.8,7.69217896 16.3078211,4.2 12,4.2 C7.69217896,4.2 4.2,7.69217896 4.2,12 C4.2,16.3078211 7.69217896,19.8 12,19.8 Z M12.6,11.7514718 L15.4242641,14.5757359 L14.5757359,15.4242641 L11.4,12.2485282 L11.4,7.8 L12.6,7.8 L12.6,11.7514718 Z"
        id="Color"
        fill="currentColor"
      />
    </g>
  </svg>
);
