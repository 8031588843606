import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { AvatarSize, Text } from 'wix-ui-tpa';

import { selectInviteStatuses } from 'store/members/selectors';

import { UserCard } from 'common/components/Cards/UserCard';

import { LetterIcon } from '../../../../icons/LetterIcon';

import { classes } from './EmailInvitesList.st.css';

interface IEmailInvitesListProps {
  emails: string[];
  selected: string[];

  onSelect(emails: string[]): void;
}

export function EmailInvitesList(props: IEmailInvitesListProps) {
  const { emails, selected, onSelect } = props;

  const { t } = useTranslation();
  const status = useSelector(selectInviteStatuses);

  useEffect(() => {
    onSelect(emails);
  }, [emails]);

  return (
    <ul>
      {emails.map((email, index) => (
        <li key={email}>
          <UserCard
            bw
            selectable
            avatarSize={AvatarSize.medium}
            divider={index !== emails.length - 1}
            selected={isSelected(email)}
            onSelect={handleSelect(email)}
            thumbnail={<LetterIcon className={classes.icon} />}
            profile={{ name: email }}
            action={
              status[email]?.success && <Text>{t('groups-web.invited')}</Text>
            }
          />
        </li>
      ))}
    </ul>
  );

  function handleSelect(email: string) {
    return function (selected: boolean) {
      if (selected) {
        onSelect([...props.selected, email]);
      } else {
        onSelect(props.selected.filter((id) => id !== email));
      }
    };
  }

  function isSelected(email: string) {
    return selected.includes(email);
  }
}
