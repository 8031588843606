import React from 'react';

import type { TPAComponentProps } from 'wix-ui-tpa/dist/src/types';

export function Visible(props: TPAComponentProps) {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" {...props}>
      <g fill="none" fillRule="evenodd">
        <g fill="currentColor">
          <g>
            <g>
              <path
                d="M12 5c5.042 0 7.859 4.423 8.837 6.324.217.423.217.929 0 1.352C19.859 14.576 17.042 19 12 19c-5.041 0-7.86-4.423-8.836-6.324-.217-.423-.217-.93 0-1.352C4.14 9.423 6.959 5 12 5zm0 1c-4.49 0-7.053 4.043-7.947 5.782-.07.136-.07.3 0 .437.894 1.738 3.457 5.78 7.947 5.78s7.053-4.042 7.947-5.78c.07-.137.07-.301 0-.437C19.053 10.042 16.49 6 12 6zm0 2.5c1.93 0 3.5 1.57 3.5 3.5s-1.57 3.5-3.5 3.5-3.5-1.57-3.5-3.5 1.57-3.5 3.5-3.5zm0 1c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5 2.5-1.121 2.5-2.5-1.122-2.5-2.5-2.5z"
                transform="translate(-64.000000, -1280.000000) translate(44.000000, 1150.000000) translate(20.000000, 130.000000)"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
