import React from 'react';

export const PhotoCameraFill: React.FC = (props) => (
  <svg width="19" height="14" viewBox="0 0 19 14" {...props}>
    <path
      fill="currentColor"
      fillRule="nonzero"
      d="M0 3a1 1 0 0 1 1-1h3.75L5.418.576A1 1 0 0 1 6.323 0h6.354a1 1 0 0 1 .905.576L14.25 2H18a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1V3zm9.5 8a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7zm0-1a2.5 2.5 0 1 1 0-5 2.5 2.5 0 0 1 0 5z"
    />
  </svg>
);
